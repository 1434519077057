import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/Seamless"
import "../css/pages/academicAdvantage.scss"

const academicadvantage = () => {
  const img = name => `/OKC/DEV/${name}`

  return (
    <Layout pageTitle="procedure-page" language={"en"}>
      <SEO
        lang={"en"}
        title="Academic Advantage Healthcare Symposium for Students"
        description="Oral Surgery Specialists of Oklahoma is proud to announce its 2022 Academic Advantage Healthcare Symposium for Students."
      />

      <div className="edu-22-program">
        <div className="edu-22-header">
          <h1>Let's Talk About Your Future.</h1>
          <h2>Healthcare Symposium for Students</h2>
          <a href="#form" className="edu-22__btn">
            Let's Go
          </a>
        </div>
        <div className="edu-22__hero">
          <div className="edu-22__hero--desktop">
            <img
              className="edu-22__hero-img"
              src={img("2022-edu-hero-image.jpg")}
              alt="program banner"
            />
          </div>
          <div className="edu-22__hero--mobile">
            <img
              className="edu-22__hero-img"
              src={img("2022-edu-mobile-hero-image.jpg")}
              alt="program banner"
            />
          </div>
        </div>

        <div className="edu-22__section about">
          <div className="edu-22__about">
            <div>
              <h3>Here’s What You’ll Learn</h3>
              <ul>
                <li>
                  When and how to start planning for your healthcare career
                </li>
                <li>Tips on preparing for college and medical school</li>
                <li>The daily routine of a medical and dental professional</li>
                <li>What you can expect from a medical career</li>
                <li>What it’s like to be an entrepreneur and a doctor</li>
                <li>The many healthcare career paths you can take</li>
              </ul>
              <p>
                In addition to learning from talented and respected medical
                professionals, representatives from local colleges and
                nonprofits will be available to provide more information about
                college admissions, specific topics, and answer questions.
              </p>
              <h4 class="edu-teal bold">DATE, TIME, & LOCATION:</h4>
              <p>
                Thursday, February 6, 2024
                <br />
                Metro Tech Conference Center
                <br />
                1900 Springlake Dr
                <br />
                Oklahoma City, OK 73111
              </p>

              <p>
                <i>
                  This event is organized by Oral Surgery Specialists of
                  Oklahoma in partnership with the{" "}
                  <a
                    href="https://www.okcps.org/Page/232"
                    target="_blank"
                    title="Learn more about Career Academies">
                    Career Academies
                  </a>{" "}
                  department at{" "}
                  <a
                    href="https://www.okcps.org/"
                    target="_blank"
                    title="Learn more about Oklahoma City Public Schools">
                    Oklahoma City Public Schools
                  </a>
                  .
                </i>
              </p>
            </div>
            <div className="edu-22__giveaway">
              <h3 className="has-text-centered">GIVEAWAY</h3>
              <ul>
                <li>$750 scholarship</li>
              </ul>
            </div>
          </div>

          <div className="edu-22__about-hero">
            <img src={img("2022-edu-second-image")} alt="edu doctor desktop" />
          </div>
        </div>
        <div className="edu-22__about-hero--mobile">
          <img
            src={img("2022-edu-mobile-second-image")}
            alt="edu doctor desktop"
          />
        </div>

        <div className="edu-22__section--color-gradient-container">
          <div className="edu-22__section--color-gradient">
            <h3>Featured Speakers</h3>
            <div className="edu-22__about-grid">
              <div className="edu-22__about-grid--content speakers">
                <Link
                  to="/robert-b-bryan-dds-oklahoma-city-ok/"
                  title="Learn more about Robert B. Bryan, DDS, FACS, FACD">
                  <h4>Robert B. Bryan II, DDS, FACS, FACD</h4>
                </Link>
                <p>Oral & Maxillofacial Surgeon</p>
                <p>Oral Surgery Specialists of Oklahoma</p>
                <br />

                <Link
                  to="/hayden-fuller-dds-ms-oklahoma-city-ok/"
                  title="Learn more about Hayden G. Fuller, DDS, MS">
                  <h4>Hayden G. Fuller, DDS, MS</h4>
                </Link>

                <p>Periodontist</p>
                <p>Oral Surgery Specialists of Oklahoma</p>
                <br />
                <a
                  href="https://integrisok.com/doctors/jeremy-t-phelps"
                  target="_blank"
                  title="Learn more about Jeremy T. Phelps, MD, MBA, FAANS">
                  <h4>Jeremy T. Phelps, MD, MBA, FAANS</h4>
                </a>
                <p>Neurosurgeon</p>
                <p>INTEGRIS Health</p>
                <br />
                <a
                  href="https://integrisok.com/doctors/amanda-gomes"
                  target="_blank"
                  title="Learn more about Amanda Gomes, MD">
                  <h4>Amanda Gomes, MD</h4>
                </a>
                <p>Critical Care Anesthesiologist</p>
                <p>INTEGRIS Health</p>
              </div>
              <div className="edu-22__about-grid--info speaker-2">
                <div className="edu-22__info-box">
                  <Link
                    to="/jeremy-c-goodson-dds-oklahoma-city-ok/"
                    title="Learn more about Jeremy C. Goodson, DDS">
                    <h4>Jeremy C. Goodson, DDS</h4>
                  </Link>

                  <p>Oral and Maxillofacial Surgeon</p>
                  <p>Oral Surgery Specialists of Oklahoma</p>
                  <br />
                  <a
                    href="https://integrisok.com/doctors/sarah-beth-mercer"
                    target="_blank"
                    title="Learn more about Sarah Beth Mercer, MD">
                    <h4>Sarah Beth Mercer, MD</h4>
                  </a>
                  <p>Critical Care Anesthesiologist</p>
                  <p>INTEGRIS Health</p>
                  <br />
                  <a
                    href="https://www.fyzical.com/oklahoma-city/About/Staff"
                    target="_blank"
                    title="Learn more about Lauren C. Peterson, PT, DPT">
                    <h4>Lauren C. Peterson, PT, DPT</h4>
                  </a>
                  <p>Owner, Clinical Director</p>
                  <p>FYZICAL Therapy & Balance Centers</p>
                </div>
              </div>
            </div>

            <br />
          </div>
        </div>

        <div className="edu-22__section">
          <div
            style={{
              position: "relative",
              height: 0,
              overflow: "hidden",
              paddingBottom: "56.25%",
              borderStyle: "none"
            }}>
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              src="https://www.youtube.com/embed/KPsQ-KTxYQs?modestbranding=1&rel=0"></iframe>
          </div>
          {/*
            <ImageSlider
              images={eventImages}
              adaptiveHeight
              useArrows
              slideClassNames="edu-22__slider"
            /> */}
        </div>
      </div>
    </Layout>
  )
}

export default academicadvantage
